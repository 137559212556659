import * as i0 from '@angular/core';
import { inject, TemplateRef, Injectable, APP_INITIALIZER, NgModule } from '@angular/core';
import { CrmConfigService, CrmModuleConfigToken } from 'common-module/core';
import { NzMessageService, NzMessageModule } from 'ng-zorro-antd/message';
import { CrmTranslateService } from 'common-module/translate';
import { of, take } from 'rxjs';
import { map } from 'rxjs/operators';
const defaultMessageConfiguration = {
  duration: 6000
};
class CrmMessageService {
  constructor() {
    this.messageService = inject(NzMessageService);
    this.configService = inject(CrmConfigService);
    this.translate = inject(CrmTranslateService);
    this.config = this.configService.mergeConfig(defaultMessageConfiguration, 'modules.message');
    this.nzDataOptions = {
      nzDuration: this.config.duration
    };
  }
  /**
   * Success feedback message. Duration of message is set from crm configuration.
   *
   * @param message String message or translation key. Example: 'errors.errorMessage'
   * @param context Message context
   * @param options Antd message options
   */
  success(message, context, options) {
    if (message instanceof TemplateRef) {
      this.messageService.success(message, {
        ...this.nzDataOptions,
        ...options
      });
      return;
    }
    this.getMessage({
      message,
      context
    }).subscribe(result => {
      this.messageService.success(result, {
        ...this.nzDataOptions,
        ...options
      });
    });
  }
  /**
   * Report error as message on screen and to console
   *
   * @param error Optional error object
   * @param message String message or translation key. Example: 'errors.errorMessage'
   * @param context Message context
   * @param options Antd message options
   */
  error(message, context, error, options) {
    if (message instanceof TemplateRef) {
      this.messageService.error(message, {
        ...this.nzDataOptions,
        ...options
      });
      return;
    }
    if (error) {
      console.error(error);
    }
    this.getMessage({
      message,
      context
    }).subscribe(result => {
      this.messageService.error(result, {
        ...this.nzDataOptions,
        ...options
      });
    });
  }
  /**
   * Notification feedback message. Duration of message is set from crm configuration.
   *
   * @param message String message or translation key. Example: 'errors.errorMessage'
   * @param context Message context
   * @param options Antd message options
   */
  info(message, context, options) {
    if (message instanceof TemplateRef) {
      this.messageService.info(message, {
        ...this.nzDataOptions,
        ...options
      });
      return;
    }
    this.getMessage({
      message,
      context
    }).subscribe(result => {
      this.messageService.info(result, {
        ...this.nzDataOptions,
        ...options
      });
    });
  }
  /**
   * Warning feedback message. Duration of message is set from crm configuration.
   *
   * @param message String message or translation key. Example: 'errors.errorMessage'
   * @param context Message context
   * @param options Antd message options
   */
  warning(message, context, options) {
    if (message instanceof TemplateRef) {
      this.messageService.warning(message, {
        ...this.nzDataOptions,
        ...options
      });
      return;
    }
    this.getMessage({
      message,
      context
    }).subscribe(result => {
      this.messageService.warning(result, {
        ...this.nzDataOptions,
        ...options
      });
    });
  }
  /**
   * Loading feedback message. Duration of this message is set to infinity, therefore dismissal of message need to be controlled
   * from outside
   *
   * @param message String message or translation key. Example: 'errors.errorMessage'
   * @param context Message context
   * @param options Antd message options
   */
  loading(message, context, options) {
    if (message instanceof TemplateRef) {
      return of(this.messageService.loading(message, options ?? {
        nzDuration: 0
      }).messageId);
    }
    return this.getMessage({
      message,
      context
    }).pipe(map(result => {
      return this.messageService.loading(result, options ?? {
        nzDuration: 0
      }).messageId;
    }));
  }
  closeMessage(id) {
    this.messageService.remove(id);
  }
  getMessage(value) {
    return this.translate.get$(value).pipe(take(1));
  }
  static {
    this.ɵfac = function CrmMessageService_Factory(t) {
      return new (t || CrmMessageService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmMessageService,
      factory: CrmMessageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmMessageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class CrmMessageModule {
  static forRoot(config) {
    return {
      ngModule: CrmMessageModule,
      providers: [{
        provide: CrmModuleConfigToken,
        useValue: {
          message: config
        },
        multi: true
      }, {
        provide: APP_INITIALIZER,
        multi: true,
        deps: [CrmMessageService],
        useFactory: service => () => CrmMessageService.instance = service
      }]
    };
  }
  static {
    this.ɵfac = function CrmMessageModule_Factory(t) {
      return new (t || CrmMessageModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmMessageModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [NzMessageModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmMessageModule, [{
    type: NgModule,
    args: [{
      imports: [NzMessageModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmMessageModule, CrmMessageService, defaultMessageConfiguration };
